<template>
  <div>
    <v-row no-gutters align="center">
      <v-col cols="2" sm="4">
        <h2>{{ $t('PageTitles.Project') }}</h2>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          outlined
          hide-details
          dense
          v-model="search"
          :label="$t('message.Search')"
          :class="$vuetify.breakpoint.mobile ? 'mt-4' : 'pa-4'"
          background-color="searchField"
        />
      </v-col>
      <v-col cols="12" md="4" align="right" v-if="isManager">
        <v-btn
          :color="$vuetify.theme.dark ? 'black' : 'white'"
          :block="$vuetify.breakpoint.mobile"
          :class="$vuetify.breakpoint.mobile ? 'my-4' : ''"
          depressed
          large
          @click="addProject"
        >
          <v-icon left>mdi-briefcase-outline</v-icon>
          {{ $t('ButtonsLabel.AddProject') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      :items="projects"
      :search="search"
      :loading="!projectsFetched"
      :headers="getHeaders"
      :items-per-page="5"
      sort-by="name"
      :dense="false"
      @click:row="showProjectDetails"
    >
      <template #[`item.leader`]="{ item }">
        <div v-if="item.leader">
          {{ item.leader.name }}
        </div>
      </template>
      <template #[`item.created`]="{ item }">
        {{ $util.formatDate(item.created) }}
      </template>
      <template #[`item.updated`]="{ item }">
        {{ $util.formatDate(item.updated) }}
      </template>
    </v-data-table>
    <create-edit-project ref="CreateEditProjectRef" @project-updated="bubbleEmit" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import apiService from '@/services/apiService.js';
import CreateEditProject from '@/components/Dialogs/CreateEditProject.vue';
export default {
  components: { CreateEditProject },
  data: () => ({
    search: null,
    projects: [],
    projectsFetched: false,
  }),
  computed: {
    ...mapGetters(['isManager']),
    getHeaders() {
      const t = this.$t.bind(this);
      return [
        { text: t('ProjectListHeaders.Name'), value: 'name' },
        { text: t('ProjectListHeaders.Leader'), value: 'leader' },
        { text: t('ProjectListHeaders.Expenses'), value: 'expenses' },
        { text: t('ProjectListHeaders.Created'), value: 'created' },
        { text: t('ProjectListHeaders.Updated'), value: 'updated' },
      ];
    },
  },
  methods: {
    async GetProjects() {
      this.projects = await apiService.getProjects();
      this.$store.dispatch('SET_PROJECTLIST', this.projects);
      this.projectsFetched = true;
    },
    showProjectDetails(Project) {
      this.$router.push({ name: 'projectDetails', params: { projectId: Project.id } });
    },
    addProject() {
      this.$refs.CreateEditProjectRef.createProject();
    },
    bubbleEmit() {
      this.GetProjects();
    },
  },
  created() {
    this.GetProjects();
  },
};
</script>